<template>
  <div>
    <div style="padding: 5px 0">
      <el-button @click="add" type="primary" size="mini" style="margin: 10px"
        >新增</el-button
      >
    </div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="100" sortable>
      </el-table-column>
      <el-table-column prop="tag_name" label="分类名称"></el-table-column>

      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            circle
            @click="edit(scope.row)"
          ></el-button>
          <el-popconfirm @confirm="del(scope.row.tag_name)" title="确定删除？">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              slot="reference"
              style="margin-left: 10px"
            ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹窗   -->
    <el-dialog
      title="信息"
      :visible.sync="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="entity">
        <el-form-item label="标签名称" label-width="150px">
          <el-input
            v-model="entity.tag_name"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "../utils/request";

export default {
  name: "Category",
  data() {
    return {
      fileList: [],
      options: [],
      text: "",
      user: {},
      tableData: [],
      entity: {},
      dialogFormVisible: false,
    };
  },
  created() {
    this.user = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {};
    this.$emit("user", this.user);
    this.load();
  },
  methods: {
    load() {
      API.get(`/api/back/tags/getAll`).then((res) => {
        this.tableData = res.data || [];
      });
    },
    add() {
      this.entity = {};
      this.fileList = [];
      this.dialogFormVisible = true;
    },
    edit(obj) {
      this.entity = JSON.parse(JSON.stringify(obj));
      this.fileList = [];
      this.dialogFormVisible = true;
    },
    save() {
      if (!this.entity.id) {
        API.post(`/api/back/tags/add`, {
          tagName: this.entity.tag_name,
        }).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
          this.load();
          this.dialogFormVisible = false;
        });
      } else {
        API.post(`/api/back/tags/edit`, {
          tagId: this.entity.id,
          tagName: this.entity.tag_name,
        }).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
          this.load();
          this.dialogFormVisible = false;
        });
      }
    },
    del(name) {
      API.post(`/api/back/tags/del`, {
        tagName: name,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.load();
      });
    },
  },
};
</script>

<style scoped>
</style>
